import { Modal as ModalAntd } from 'antd';
import { Button } from 'components/Button/Button';
import { forwardRef, useImperativeHandle, useState, useContext } from 'react';
import './Step1Modal.css';
import helpIcon from 'assets/img/help-2.png';
import Help from 'components/Help/Help';
import closeIcon from 'assets/img/close-2.png';
import { modalInput } from 'pages/Step1/Step1';
import Content from './Content/Content';
import { appContext } from 'context/AppContext';
import { useNavigate } from 'react-router-dom';

export const Modal = forwardRef<ModalRef, ModalProps>((props: ModalProps, ref: any) => {
  const navigate = useNavigate();

  const { step1, setStep1, setStep2 } = useContext(appContext);

  const [items, setItems] = useState<Array<any>>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allFetched, setAllFetched] = useState(false);

  useImperativeHandle(ref, () => ({
    showModal(data: modalInput) {
      setItems(data.items);
      setAllFetched(data.allFetched);
      setIsModalVisible(true);
    }
  }));

  const handleCancel = () => {
    setItems([]);
    setAllFetched(false);
    setIsModalVisible(false);
    setStep1(null);
    setStep2(null);
  };

  const helpEntries = [
    <li key={1}>Sélectionnez votre article</li>,
    <li key={2}>Cliquez sur "valider" </li>,
    <li key={3}>Cliquez sur le bouton "Tout afficher" pour afficher l'ensemble des produits </li>
  ];

  const helpWrapper = (
    <div className="help-wrapper">
      <p className="help-label">Aide ?</p>
      <img src={helpIcon} alt="help-icon" className="help-icon" />
    </div>
  );

  const footer = (
    <div className="footer-wrapper">
      <div className="footer-submit-button-wrapper">
        <Button
          disabled={step1 == null}
          title="Valider"
          type="default"
          onClick={() => {
            navigate('/step-2');
          }}
        ></Button>
      </div>
      <Help
        content={helpWrapper}
        title="Bienvenue dans l’aide"
        default={false}
        helpEntries={helpEntries}
      ></Help>
    </div>
  );
  const header = (
    <div className="header-wrapper">
      <p className="header-title">{props.title}</p>
      <img src={closeIcon} alt="close-icon" className="close-icon" onClick={handleCancel} />
    </div>
  );
  return (
    <>
      <ModalAntd
        closeIcon={null}
        width={656}
        title={header}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={footer}
        maskClosable={false}
        destroyOnClose={true}
      >
        {<Content allFetched={allFetched} items={items}></Content>}
      </ModalAntd>
    </>
  );
});

export default Modal;
export type ModalProps = {
  title: string;
};

export type ModalRef = {
  showModal: (data: modalInput) => void;
};
