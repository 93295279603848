import { Modal as ModalAntd, Spin } from 'antd';
import { Button } from 'components/Button/Button';
import { forwardRef, useImperativeHandle, useState, useContext } from 'react';
import './Step2Modal.css';
import closeIcon from 'assets/img/close-2.png';
import { appContext } from 'context/AppContext';
import { useNavigate } from 'react-router-dom';
import { modalStep2Input } from 'pages/Step2/Step2';
import Content from './Content/Content';

export const ModalStep2 = forwardRef<ModalStep2Ref, ModalProps>((props: ModalProps, ref: any) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { step2, setStep2 } = useContext(appContext);

  const [items, setItems] = useState<Array<any>>([]);
  const [allFetched, setAllFetched] = useState(false);
  const [dimensions, setDimensions] = useState({ width: '0', height: '0' });
  const [quantity, setQuantity] = useState(1);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [modalState, setModalState] = useState<1 | 2>(1);

  const [message, setMessage] = useState('');

  const handleFetchDimensions = async () => {
    setLoading(true);
    try {
      const body = {
        message: message,
        product_name: step2?.productName
      };
      const response = await fetch(
        `https://api.izimade-ai.com:5099/extract-entities?message=${message}&product_name=${step2?.productName}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      );
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      setStep2({
        productCode: step2?.productCode!,
        rangeCode: step2?.rangeCode!,
        quantity: result.quantity[0] || 1,
        dimensions: {
          width: result.dimension[1] || 0,
          height: result.dimension[0] || 0
        },
        productName: step2?.productName || ''
      });
      setModalState(2);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    showModal(data: modalStep2Input) {
      setItems(data.items);
      setQuantity(data.quantity);
      setDimensions(data.dimensions);
      setAllFetched(data.allFetched);
      setStep2({
        productCode: step2?.productCode!,
        rangeCode: step2?.rangeCode!,
        quantity: data.quantity,
        dimensions: data.dimensions,
        productName: ''
      });
      setIsModalVisible(true);
      setMessage(data.message);
    }
  }));

  const handleCancel = () => {
    setModalState(1);
    setItems([]);
    setDimensions({ width: '0', height: '0' });
    setQuantity(0);
    setAllFetched(false);
    setStep2(null);
    setIsModalVisible(false);
  };

  const footerState1 = (
    <div className="footer-wrapper">
      <div className="footer-submit-button-wrapper">
        <Button
          disabled={step2 == null || step2?.productCode == null}
          title="Valider"
          type="default"
          onClick={() => {
            handleFetchDimensions();
            // setModalState(2);
          }}
        ></Button>
      </div>
    </div>
  );
  const footerState2 = (
    <div className="footer-wrapper">
      <div className="footer-submit-button-wrapper">
        <Button
          disabled={false}
          title="Ajouter"
          type="default"
          onClick={() => {
            navigate('/step-3');
          }}
        ></Button>
      </div>
    </div>
  );
  const header = (
    <div className="header-wrapper">
      <p className="header-title">{props.title}</p>
      <img src={closeIcon} alt="close-icon" className="close-icon" onClick={handleCancel} />
    </div>
  );

  return (
    <>
      <ModalAntd
        closeIcon={null}
        width={690}
        title={header}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={modalState === 1 ? footerState1 : footerState2}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Spin spinning={loading} size="large">
          <Content
            modalState={modalState}
            setModalState={setModalState}
            quantity={quantity}
            dimensions={dimensions}
            allFetched={allFetched}
            items={items}
          ></Content>
        </Spin>
      </ModalAntd>
    </>
  );
});

export default ModalStep2;
export type ModalProps = {
  title: string;
};

export type ModalStep2Ref = {
  showModal: (data: modalStep2Input) => void;
};
