import { ReactNode } from 'react';
import './Radio.css';

export function Radio(props: RadioProps) {
  return (
    <div
      className="modern-radio-container"
      onClick={() => {
        props.onChange(props.value);
      }}
    >
      <div className="content">{props.content}</div>
      <div className={`radio-outer-circle ${props.value !== props.selected && 'unselected'}`}>
        <div
          className={`radio-inner-circle ${props.value !== props.selected && 'unselected-circle'}`}
        />
      </div>
    </div>
  );
}

export default Radio;

export type RadioProps = {
  content: ReactNode;
  onChange: (value: any) => any;
  selected: any;
  value: any;
};
