import './Button.css';
export function Button(props: ButtonProps) {
  const classes: string = `button ${props.type}`;
  return (
    <button
      disabled={props.disabled !== undefined ? props.disabled : false}
      className={classes}
      onClick={props.onClick}
    >
      {props.title}
    </button>
  );
}

export type ButtonProps = {
  title: string;
  onClick: () => any;
  type: 'default' | 'mic' | 'small-icon';
  disabled: boolean;
};
