var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BUFFER_SIZE } from './constants';
var IZISpeechMedia = /** @class */ (function () {
    function IZISpeechMedia() {
    }
    IZISpeechMedia.prototype.startRecording = function (socket) {
        var _this = this;
        return navigator.mediaDevices
            .getUserMedia({ audio: true, video: false })
            .then(function (stream) {
            _this.mediaStream = stream;
            _this.audioContext = new window.AudioContext();
            _this.socket = socket;
            socket.config(_this.audioContext.sampleRate);
            // Capture mic audio data into a stream
            _this.audioIn = _this.audioContext.createMediaStreamSource(stream);
            // only record mono audio and call a js function after a buffer of
            // size this.bufferSize is full
            _this.recorder = _this.audioContext.createScriptProcessor(BUFFER_SIZE, 1, 1);
            //specify the processing function
            _this.recorder.onaudioprocess = _this.onAudioCallBack.bind(_this);
            // connect audioIn data to the recorder
            _this.audioIn.connect(_this.recorder);
            // connect recorder's output to previous destination
            _this.recorder.connect(_this.audioContext.destination);
        });
    };
    IZISpeechMedia.prototype.stopRecording = function () {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        try {
                            (_a = this.mediaStream) === null || _a === void 0 ? void 0 : _a.getAudioTracks().forEach(function (track) { return track.stop(); });
                        }
                        catch (error) { }
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, ((_b = this.audioContext) === null || _b === void 0 ? void 0 : _b.close())];
                    case 2:
                        _e.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _e.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        try {
                            (_c = this.recorder) === null || _c === void 0 ? void 0 : _c.disconnect();
                        }
                        catch (error) { }
                        try {
                            (_d = this.audioIn) === null || _d === void 0 ? void 0 : _d.disconnect();
                        }
                        catch (error) { }
                        return [2 /*return*/];
                }
            });
        });
    };
    IZISpeechMedia.prototype.float32ToLinear16 = function (float32Arr) {
        var int16 = new Int16Array(float32Arr.length);
        for (var i = 0; i < float32Arr.length; ++i) {
            // force number in [-1, 1]
            var s = Math.max(-1, Math.min(1, float32Arr[i]));
            // convert 32 bit float -> 16 bit int.
            // 0x7fff = max 16 bit num. 0x8000 = min 16 bit num.
            int16[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
        }
        return int16;
    };
    IZISpeechMedia.prototype.onAudioCallBack = function (event) {
        var _a;
        var float32Audio = event.inputBuffer.getChannelData(0) || new Float32Array(BUFFER_SIZE);
        var linear16 = this.float32ToLinear16(float32Audio);
        (_a = this.socket) === null || _a === void 0 ? void 0 : _a.send(linear16.buffer);
    };
    return IZISpeechMedia;
}());
export { IZISpeechMedia };
