var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LISTEN_ENDED_EVENT_NAME, LISTEN_STARTED_EVENT_NAME, TIMEOUT_TIME, TRANSCRIPT_UPDATED_EVENT_NAME } from './constants.js';
import { initializeDom, isInitialized, shake, showErrorTooltip, updateMic, updateTranscript } from './dom.js';
import { dispatchListenEndedEvent, dispatchListenStartedEvent, dispatchTranscriptUpdatedEvent, initializeEvents } from './events.js';
import { IZISpeechMedia } from './media.js';
import { IZISpeechWebSocket } from './socket.js';
import { IZISpeechState } from './types.js';
var IZISpeech = /** @class */ (function () {
    function IZISpeech() {
        this.state = IZISpeechState.DEFAULT;
        if (!isInitialized()) {
            this.checkMediaSupport();
            var initializeDomResponse = initializeDom();
            this.wrapperElement = initializeDomResponse[0];
            this.transcriptElement = initializeDomResponse[1];
            this.micElement = initializeDomResponse[2];
            this.errorElement = initializeDomResponse[3];
            initializeEvents(this.wrapperElement, this.iziSpeechClickHandler.bind(this));
        }
        else {
            throw new Error('IZIspeech already initialized');
        }
    }
    IZISpeech.prototype.setTranscriptUpdatedEventCallBack = function (callback) {
        if (this.wrapperElement)
            this.wrapperElement.addEventListener(TRANSCRIPT_UPDATED_EVENT_NAME, callback);
    };
    IZISpeech.prototype.setListenEndedEventCallBack = function (callback) {
        if (this.wrapperElement)
            this.wrapperElement.addEventListener(LISTEN_ENDED_EVENT_NAME, callback);
    };
    IZISpeech.prototype.setListenStartedEventCallBack = function (callback) {
        if (this.wrapperElement)
            this.wrapperElement.addEventListener(LISTEN_STARTED_EVENT_NAME, callback);
    };
    IZISpeech.prototype.checkMediaSupport = function () {
        try {
            window.AudioContext =
                window.AudioContext || window.webkitAudioContext;
            navigator.getUserMedia =
                navigator.getUserMedia ||
                    navigator.webkitGetUserMedia ||
                    navigator.mozGetUserMedia ||
                    navigator.msGetUserMedia;
        }
        catch (e) {
            this.state = IZISpeechState.NOT_SUPPORTED;
            updateMic(this.micElement, IZISpeechState.NOT_SUPPORTED);
            showErrorTooltip('Microphone acccess not supported on this browser');
            shake(this.micElement);
            throw new Error("Browser doesn't support Media Input");
        }
        return navigator.getUserMedia && window.AudioContext;
    };
    IZISpeech.prototype.iziSpeechClickHandler = function () {
        switch (this.state) {
            case IZISpeechState.ACTIVE:
                this.stopListening();
                break;
            case IZISpeechState.DEFAULT:
                this.startListening();
                break;
            case IZISpeechState.LOADING:
                console.warn('IZISpeech still loading');
                break;
            case IZISpeechState.NOT_SUPPORTED:
                showErrorTooltip('Microphone acccess not supported on this browser');
                console.warn('IZISpeech not supported on this browser');
                shake(this.micElement);
                break;
            case IZISpeechState.USER_NOT_ACCEPT:
                showErrorTooltip('Please enable microhpone permission and reload the page.');
                shake(this.micElement);
                console.warn('IZISpeech not supported on this browser');
                break;
            default:
                break;
        }
    };
    IZISpeech.prototype.startListening = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.state = IZISpeechState.LOADING;
                        updateMic(this.micElement, IZISpeechState.LOADING);
                        this.iziSpeechSocket = new IZISpeechWebSocket();
                        return [4 /*yield*/, this.iziSpeechSocket.open(this.onSocketMessageReceived.bind(this))];
                    case 1:
                        _a.sent();
                        this.iziSpeechMedia = new IZISpeechMedia();
                        this.iziSpeechMedia
                            .startRecording(this.iziSpeechSocket)
                            .then(function () {
                            dispatchListenStartedEvent(_this.wrapperElement);
                            _this.state = IZISpeechState.ACTIVE;
                            updateMic(_this.micElement, IZISpeechState.ACTIVE);
                        })
                            .then(function () { return _this.startTimeOutCallBack(); })
                            .catch(function (error) {
                            _this.state = IZISpeechState.USER_NOT_ACCEPT;
                            updateMic(_this.micElement, IZISpeechState.USER_NOT_ACCEPT);
                            showErrorTooltip('Please enable microhpone permission and reload the page.');
                            shake(_this.micElement);
                            console.log(error);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    IZISpeech.prototype.stopListening = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.state = IZISpeechState.LOADING;
                        updateMic(this.micElement, IZISpeechState.LOADING);
                        (_a = this.iziSpeechSocket) === null || _a === void 0 ? void 0 : _a.close();
                        return [4 /*yield*/, ((_b = this.iziSpeechMedia) === null || _b === void 0 ? void 0 : _b.stopRecording())];
                    case 1:
                        _c.sent();
                        clearTimeout(this.timeoutId);
                        this.currentTranscript = '';
                        updateMic(this.micElement, IZISpeechState.DEFAULT);
                        updateTranscript(false);
                        this.state = IZISpeechState.DEFAULT;
                        return [2 /*return*/];
                }
            });
        });
    };
    IZISpeech.prototype.onSocketMessageReceived = function (event) {
        var dataString = event.data;
        var results = JSON.parse(dataString);
        var result = results[results.length - 1];
        var transcript = result[0];
        var isFinal = transcript.is_final;
        var alternative = transcript.alternatives[0];
        var message = alternative.transcript;
        updateTranscript(true, message);
        dispatchTranscriptUpdatedEvent(this.wrapperElement, message);
        this.currentTranscript = message;
        if (isFinal) {
            this.stopListening();
            dispatchListenEndedEvent(this.wrapperElement, message);
        }
    };
    IZISpeech.prototype.startTimeOutCallBack = function () {
        var _this = this;
        this.timeoutId = setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.state === IZISpeechState.ACTIVE)) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        dispatchListenEndedEvent(this.wrapperElement, this.currentTranscript);
                        updateTranscript(false);
                        return [4 /*yield*/, this.stopListening()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); }, TIMEOUT_TIME);
    };
    return IZISpeech;
}());
export { IZISpeech };
