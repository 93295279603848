import { createContext } from 'react';

export const appContext = createContext<AppContext>({
  step1: null,
  step2: null,
  setStep1: (s: Step1) => {},
  setStep2: (s: Step2) => {}
});

export type AppContext = {
  step1: Step1;
  step2: Step2;
  setStep1: (s: Step1) => void;
  setStep2: (s: Step2) => void;
};

export type Step1 = {
  pricerId: string;
  supplierContextId: string;
  brandName: string;
} | null;

export type Step2 = {
  productCode: string;
  dimensions: {
    height: string;
    width: string;
  };
  quantity: number;
  rangeCode: string;
  productName: string;
} | null;
