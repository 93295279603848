// import  micIcon from './assets/mic.png'
import { ERROR_ELEMENT_ID, INSTANCE_ATTRIBUTE_STRING, MIC_ACTIVE_ASSET, MIC_BUTTON_ELEMENT_ID, MIC_DEFAULT_ASSET, MIC_LOADING_ASSET, MIC_NOT_SUPPORTED_ASSET, TRANSCRIPT_ELEMENT_ID, WRAPPER_ELEMENT_ID } from './constants';
import { IZISpeechState } from './types';
export function initializeDom() {
    var wrapperElement = document.getElementById(WRAPPER_ELEMENT_ID);
    if (wrapperElement === null) {
        throw new Error("Wrapper element doesn't exist. Please create div with id izi-speech-wrapper ");
    }
    wrapperElement === null || wrapperElement === void 0 ? void 0 : wrapperElement.setAttribute(INSTANCE_ATTRIBUTE_STRING, 'true');
    wrapperElement.classList.add('mic-wrapper');
    var TRANSCRIPT_ELEMENT_STRING = "<p style=\"display:none;margin:0px;\" id=\"".concat(TRANSCRIPT_ELEMENT_ID, "\" class=\"transcript\"></p>");
    wrapperElement.innerHTML += TRANSCRIPT_ELEMENT_STRING;
    var transcriptElement = document.getElementById(TRANSCRIPT_ELEMENT_ID);
    var ERROR_ELEMENT_STRING = "<p style=\"opacity:0;margin:0px;\" id=\"".concat(ERROR_ELEMENT_ID, "\" class=\"error-message\"></p>");
    wrapperElement.innerHTML += ERROR_ELEMENT_STRING;
    var errorElement = document.getElementById(ERROR_ELEMENT_ID);
    var MIC_ELMENT_STRING = "<div id=\"".concat(MIC_BUTTON_ELEMENT_ID, "\" class=\"mic-button\"><img src=").concat(MIC_DEFAULT_ASSET, "></img></div>");
    wrapperElement.innerHTML += MIC_ELMENT_STRING;
    var micElement = document.getElementById(MIC_BUTTON_ELEMENT_ID);
    return [wrapperElement, transcriptElement, micElement, errorElement];
}
export function updateTranscript(visible, text) {
    if (text === void 0) { text = ''; }
    var transcriptElement = document.getElementById(TRANSCRIPT_ELEMENT_ID);
    if (visible) {
        transcriptElement.innerHTML = text;
        transcriptElement.style.display = 'block';
    }
    else {
        transcriptElement.style.display = 'none';
        transcriptElement.innerHTML = text;
    }
}
export function updateMic(element, state) {
    var imageElement;
    switch (state) {
        case IZISpeechState.DEFAULT:
            imageElement = "<img src=".concat(MIC_DEFAULT_ASSET, "></img>");
            break;
        case IZISpeechState.ACTIVE:
            imageElement = "<img src=".concat(MIC_ACTIVE_ASSET, "></img>");
            break;
        case IZISpeechState.LOADING:
            imageElement = "<img style=\"opacity:0.4;\" src=".concat(MIC_LOADING_ASSET, "></img>");
            break;
        case IZISpeechState.NOT_SUPPORTED:
            imageElement = "<img style=\"opacity:0.8;\" src=".concat(MIC_NOT_SUPPORTED_ASSET, "></img>");
            break;
        case IZISpeechState.USER_NOT_ACCEPT:
            imageElement = "<img style=\"opacity:0.8;\" src=".concat(MIC_NOT_SUPPORTED_ASSET, "></img>");
            break;
        default:
            imageElement = "<img src=".concat(MIC_DEFAULT_ASSET, "></img>");
            break;
    }
    element.innerHTML = imageElement;
}
export function isInitialized() {
    var wrapperElement = document.getElementById(WRAPPER_ELEMENT_ID);
    if (wrapperElement === null) {
        throw new Error("Wrapper element doesn't exist. Please create div with id izi-speech-wrapper ");
    }
    return wrapperElement.hasAttribute(INSTANCE_ATTRIBUTE_STRING);
}
export function shake(element) {
    new Promise(function (resolve) {
        element.classList.add('shake');
        resolve(1);
    }).then(function () {
        setTimeout(function () {
            element.classList.remove('shake');
        }, 1000);
    });
}
export function showErrorTooltip(errorMessage) {
    if (errorMessage === void 0) { errorMessage = ''; }
    var errorElement = document.getElementById(ERROR_ELEMENT_ID);
    errorElement.innerHTML = errorMessage;
    errorElement.style.opacity = '1';
    setTimeout(function () {
        errorElement.style.transition = '0.8s';
        errorElement.style.opacity = '0';
    }, 3000);
}
