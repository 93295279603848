import { CLICK, LISTEN_ENDED_EVENT_NAME, LISTEN_STARTED_EVENT_NAME, TRANSCRIPT_UPDATED_EVENT_NAME } from './constants';
export function initializeEvents(element, onClickCallBack) {
    element.addEventListener(CLICK, onClickCallBack);
}
export function dispatchListenStartedEvent(element) {
    var event = new CustomEvent(LISTEN_STARTED_EVENT_NAME);
    element.dispatchEvent(event);
}
export function dispatchListenEndedEvent(element, transcript) {
    if (transcript && transcript.length > 0) {
        var event_1 = new CustomEvent(LISTEN_ENDED_EVENT_NAME, {
            detail: { transcript: transcript }
        });
        element.dispatchEvent(event_1);
    }
}
export function dispatchTranscriptUpdatedEvent(element, transcript) {
    if (transcript && transcript.length > 0) {
        var event_2 = new CustomEvent(TRANSCRIPT_UPDATED_EVENT_NAME, {
            detail: { transcript: transcript }
        });
        element.dispatchEvent(event_2);
    }
}
