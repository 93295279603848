import { BackButton } from 'components/BackButton/BackButton';
import Help from 'components/Help/Help';
import { useRef, useState } from 'react';
import './Step1.css';
import logoFournisseur1 from 'assets/img/fournisseur-1.png';
import logoFournisseur2 from 'assets/img/fournisseur-2.png';
import logoFournisseur3 from 'assets/img/fournisseur-3.png';
import logoFournisseur4 from 'assets/img/fournisseur-4.png';
import logoFournisseur5 from 'assets/img/fournisseur-5.png';
import Speech from 'components/Speech/Speech';
import Modal, { ModalRef } from 'components/Step1Modal/Step1Modal';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

function Step1() {
  const defaultHelpVisible = false;

  const navigate = useNavigate();
  const handleOnClick = () => navigate('/');

  const [loading, setLoading] = useState(false);
  var data: modalInput = {
    items: [],
    allFetched: false
  };

  const handleFetchPricers = async (message: string) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.izimade-ai.com:5099/predict-pricer?message=${message}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json'
          }
        }
      );
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      data.items = result.output;
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchAllPricers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.izimade-ai.com:5099/get-all-pricers`, {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      var items = result.output.map((i: any) => {
        return {
          pricerLabel: i.pricerLabel,
          pricerId: i.pricerId,
          brandName: i.brandName,
          pricerLogoUrl: i.lastPricerVersion.pricerVersionLogoUrl,
          supplierContextId: i.supplierContextId
        };
      });
      data.items = items;
      data.allFetched = true;
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  setTimeout(() => {
    setBackdropVisible(false);
  }, 5000);
  const [backdropVisible, setBackdropVisible] = useState(true);
  const backDropClickHandler = () => {
    return setBackdropVisible(false);
  };
  const helpEntries = [
    <li key={1}>Cliquez sur le micro pour lancer l'écoute. </li>,
    <li key={2}>Dictez uniquement le nom de votre fournisseur </li>,
    <li key={3}>Une fois que vous avez terminé de parler le micro s'éteint. </li>,
    <li key={4}>Vous ne savez pas comment rechercher votre fournisseur ? </li>
  ];

  const itemsExamples = (
    <div className="items-examples-wrapper">
      <div className="item">
        <img src={logoFournisseur1} alt="Exemple fournisseur" />
      </div>
      <div className="item">
        <img src={logoFournisseur2} alt="Exemple fournisseur" />
      </div>
      <div className="item">
        <img src={logoFournisseur3} alt="Exemple fournisseur" />
      </div>
      <div className="item">
        <img src={logoFournisseur4} alt="Exemple fournisseur" />
      </div>
      <div className="item">
        <img src={logoFournisseur5} alt="Exemple fournisseur" />
      </div>
    </div>
  );
  const childRef = useRef<ModalRef>(null);

  const handleSpeechChange = async (evt: any) => {
    await handleFetchPricers(evt);
    childRef.current?.showModal(data);
  };

  const handleShowAllClick = async () => {
    await handleFetchAllPricers();
    childRef.current?.showModal(data);
  };
  return (
    <Spin spinning={loading} size="large">
      <div className="container">
        {backdropVisible && <div className="backdrop" onClick={backDropClickHandler}></div>}
        <div className="left">
          <p className="step-title">1 - Choix du fournisseur</p>
          <BackButton title="← Retour" onClick={handleOnClick} />
        </div>
        <div className="middle">
          <h2>
            Cliquez sur le <i className="txt-blue">micro</i>
            <br />
            et <i className="txt-blue">dictez</i> le nom de votre fournisseur
          </h2>
          {itemsExamples}
          <p className="view-all-message" onClick={handleShowAllClick}>
            La commande vocale ne trouve pas votre fournisseur ? <br /> Cliquez ici !
          </p>
          <div className="hint-wrapper">
            <p>
              Cliquez et <i className="txt-blue">dites</i> par exemple : “
              <i className="txt-blue">Bobby menuiserie</i>”
            </p>
          </div>
          <Speech onChange={handleSpeechChange} />
        </div>
        <div className="right">
          <Help
            default={defaultHelpVisible}
            title="Bienvenue dans l’aide"
            helpEntries={helpEntries}
          />
        </div>
        <Modal ref={childRef} title="Choisissez votre bibliothèque fournisseur" />
      </div>
    </Spin>
  );
}
export default Step1;

export type modalInput = {
  items: Array<any>;
  allFetched: boolean;
};
