import { useEffect } from 'react';
import './Speech.css';
import { IZISpeech } from 'izispeech';

function Speech(props: SpeechProps) {
  const speechWrapperElement = <div id="izi-speech-wrapper"></div>;
  var iziSpeechInstance: IZISpeech;

  useEffect(() => {
    try {
      iziSpeechInstance = new IZISpeech();
      iziSpeechInstance.setListenEndedEventCallBack((event: any) => {
        props.onChange(event.detail.transcript);
      });
    } catch (error) {}
    return () => {
      try {
        iziSpeechInstance.stopListening();
      } catch (error) {
        console.log(error);
      }
    };
  }, []);

  return speechWrapperElement;
}
export default Speech;

export type SpeechProps = {
  onChange: (evt: any) => void;
};
