import './Content.css';
import { useState, useContext } from 'react';
import Radio from 'components/Radio/Radio';
import { Spin } from 'antd';
import { appContext } from 'context/AppContext';

function Content(props: Step1Props) {
  const { setStep1 } = useContext(appContext);

  const [selected, setSelected] = useState(-1);
  const [items, setItems] = useState(props.items);
  const [allFetched, setAllFetched] = useState(props.allFetched);

  const [loading, setLoading] = useState(false);
  const handleFetchAllPricers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.izimade-ai.com:5099/get-all-pricers`, {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      var items = result.output.map((i: any) => {
        return {
          pricerLabel: i.pricerLabel,
          pricerId: i.pricerId,
          brandName: i.brandName,
          pricerLogoUrl: i.lastPricerVersion.pricerVersionLogoUrl,
          supplierContextId: i.supplierContextId
        };
      });
      setAllFetched(true);
      setItems(items);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  var RadioContents = items.map((item) => {
    return (
      <div className="item-wrapper">
        <div className="avatar">
          <img src={item.pricerLogoUrl} alt="" />
        </div>
        <p className="label">{item.brandName}</p>
      </div>
    );
  });
  const choose = (pricerId: number, _items?: []) => {
    setSelected(pricerId);
    const selected = (_items ? _items : items).find((i) => i.pricerId === pricerId);
    setStep1({
      pricerId: pricerId.toString(),
      supplierContextId: selected.supplierContextId,
      brandName: selected.brandName
    });
  };

  return (
    <>
      <Spin spinning={loading} size="large">
        <div className="step-1-wrapper">
          <div className="items-list">
            {items.map((i: any, idx: number) => {
              return (
                <Radio
                  value={i.pricerId}
                  selected={selected}
                  key={i.pricerId}
                  content={RadioContents[idx]}
                  onChange={choose}
                />
              );
            })}
          </div>
          {!allFetched && (
            <p className="show-all-wrapper" onClick={handleFetchAllPricers}>
              Tout afficher
            </p>
          )}
        </div>
      </Spin>
    </>
  );
}
export default Content;

export type Step1Props = {
  items: Array<any>;
  allFetched: boolean;
};
