import { BackButton } from 'components/BackButton/BackButton';
import Help from 'components/Help/Help';
import './Step2.css';
import Speech from 'components/Speech/Speech';
import { useRef, useContext, useState } from 'react';
import { appContext } from 'context/AppContext';
import { ModalStep2, ModalStep2Ref } from 'components/Step2Modal/Step2Modal';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Spin } from 'antd';

function Step2() {
  const defaultHelpVisible = false;

  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate('/step-1');
    setStep1(null);
  };

  const { step1, setStep1 } = useContext(appContext);

  useEffect(() => {
    if (step1 == null) navigate('/step-1');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step1]);

  const [loading, setLoading] = useState(false);
  var data: modalStep2Input = {
    dimensions: { height: '0', width: '0' },
    quantity: 1,
    items: [],
    allFetched: false,
    message: ''
  };

  const handleFetchItems = async (message: string) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.izimade-ai.com:5099/predict-item?message=${message}&pricer_id=${step1?.pricerId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json'
          }
        }
      );
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      data.items = result.output;
      data.dimensions = {
        width: '0',
        height: '0'
      };
      data.quantity = 1;
      data.message = message;
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchAllItems = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.izimade-ai.com:5099/get-all-items?pricer_id=${step1?.pricerId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json'
          }
        }
      );
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      var items = result.output.map((i: any) => {
        return {
          productCode: i.productCode,
          productName: i.productName,
          productGroupName: i.productGroupName,
          rangeName: i.rangeName,
          rangeGroupName: i.rangeGroupName,
          rangeCode: i.rangeCode || ''
        };
      });
      data.items = items;
      data.dimensions = {
        width: '0',
        height: '0'
      };
      data.quantity = 1;
      data.allFetched = true;
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const helpEntries = [<li key={1}>Vous ne savez pas comment rechercher votre article ?</li>];

  const suggestions = (
    <div className="suggestions-wrapper">
      <p className="title">Voici quelques exemples que vous pouvez dicter :</p>
      <ul>
        <li>
          “<i className="txt-orange">Deux</i> <i className="txt-blue">fenêtres 2 vantaux </i>
          <i className="txt-green">1500 par 1200</i>“
        </li>
        <li>
          “<i className="txt-blue">Fenêtres 2 vantaux </i>
          <i className="txt-green">1500 par 1200</i> <i className="txt-orange">quantité 2</i>”
        </li>
        <li>
          “<i className="txt-blue">Fenêtres 2 vantaux</i>{' '}
          <i className="txt-green">dimension 1500 par 1200 </i>
          <i className="txt-orange">quantité 2</i>”
        </li>
      </ul>
    </div>
  );

  const childRef = useRef<ModalStep2Ref>(null);

  const handleSpeechChange = async (evt: any) => {
    await handleFetchItems(evt);
    childRef.current?.showModal(data);
  };

  const handleShowAllClick = async () => {
    await handleFetchAllItems();
    childRef.current?.showModal(data);
  };

  return (
    <Spin spinning={loading} size="large">
      <div className="container step-2">
        <div className="left">
          <p className="step-title">2 - Choix du produit</p>
          <BackButton title="← Retour" onClick={handleOnClick} />
        </div>
        <div className="middle">
          <h2>
            Quel <i className="txt-blue">produit</i> de menuiserie de la bibliothèque <br />
            {step1?.brandName + ' '}
            souhaitez vous configurer ?
          </h2>
          {suggestions}
          <p className="view-all-message" onClick={handleShowAllClick}>
            Afficher l'ensemble des produits
          </p>
          <div className="hint-wrapper">
            <p>
              Dictez <i className="txt-orange">la quantité</i>, <i className="txt-blue">le nom</i>{' '}
              et <i className="txt-green">la dimension </i>
              <br /> du produit que vous souhaitez configurer.
            </p>
          </div>
          <Speech onChange={handleSpeechChange} />
        </div>
        <div className="right">
          <Help
            default={defaultHelpVisible}
            title="Bienvenue dans l’aide"
            helpEntries={helpEntries}
          />
        </div>
        <ModalStep2 ref={childRef} title="Choisissez votre produit" />
      </div>
    </Spin>
  );
}
export default Step2;

export type modalStep2Input = {
  items: Array<any>;
  dimensions: {
    width: string;
    height: string;
  };
  quantity: number;
  allFetched: boolean;
  message: string;
};
