import './Home.css';
import { Button } from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import Help from 'components/Help/Help';

function Home() {
  const defaultHelpVisible = false;

  const navigate = useNavigate();
  const handleOnClick = () => navigate('/step-1');
  const helpEntries = [
    <li key={1}>On vous explique comment se servir du configurateur vocal </li>,
    <li key={2}>Ce que vous devez dictez </li>,
    <li key={3}>De quel façon le dicter </li>,
    <li key={4}>Cliquer sur "Lancer la configuration" pour configurer votre produit </li>
  ];
  return (
    <div className="container">
      <div className="left"></div>
      <div className="middle">
        <h1>
          Bienvenue <br /> dans le configurateur <i className="txt-blue">vocal</i> ELCIA ONLINE.
        </h1>
        <h3>
          Cliquez sur le <b>bouton ci-dessous</b> pour <br />
          configurer votre produit.{' '}
        </h3>
        <Button
          disabled={false}
          type="default"
          onClick={handleOnClick}
          title="Lancer la configuration"
        ></Button>
      </div>
      <div className="right">
        <Help
          default={defaultHelpVisible}
          title="Bienvenue dans l’aide"
          helpEntries={helpEntries}
        />
      </div>
    </div>
  );
}

export default Home;
