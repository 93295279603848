import './Help.css';
import helpLogo from 'assets/img/help.svg';
import closeLogo from 'assets/img/close.png';
import { Popover } from 'antd';
import { ReactNode, useState } from 'react';
function Help(props: HelpProps) {
  const [visible, setVisible] = useState(props.default !== undefined ? props.default : true);
  const hide = () => {
    setVisible(false);
  };
  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const header = (
    <div className="help-header-container">
      <img className="close-icon" src={closeLogo} onClick={hide} alt="close-icon" />
      <p>{props.title}</p>
    </div>
  );
  const content = (
    <div>
      <ul className="help-entries">{props.helpEntries}</ul>
    </div>
  );

  return (
    <div className="help-container">
      <Popover
        placement="topLeft"
        title={header}
        content={content}
        trigger="click"
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        {props.content !== undefined ? props.content : <img src={helpLogo} alt="" />}
      </Popover>
    </div>
  );
}
export default Help;

export type HelpProps = {
  title: string;
  helpEntries: Array<ReactNode>;
  content?: ReactNode;
  default?: boolean;
};
