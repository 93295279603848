import { BackButton } from 'components/BackButton/BackButton';
import Help from 'components/Help/Help';
import './Step3.css';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { appContext } from 'context/AppContext';
import { Spin } from 'antd';

function Step3() {
  const defaultHelpVisible = false;

  const { step1, step2, setStep2 } = useContext(appContext);
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate('/step-2');
    setStep2(null);
  };

  useEffect(() => {
    if (step1 == null || step2 == null) navigate('/step-1');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step1, step2]);

  const [loading, setLoading] = useState(false);

  const [link, setLink] = useState<string>('');

  const helpEntries = [
    <li key={1}>
      Cliquez dans les champs du configurateur pour modifier :
      <br />
      -la quantité
      <br />
      -l'article
      <br />
      -les dimensions{' '}
    </li>,
    <li key={2}>Une fois que vous avez terminé de parler, le micro s'éteint. </li>
  ];

  useEffect(() => {
    const getConfigUrl = async () => {
      setLoading(true);
      try {
        const body = {
          supplier_context_id: step1?.supplierContextId,
          pricer_id: step1?.pricerId,
          range_code: step2?.rangeCode,
          product_code: step2?.productCode,
          quantity: step2?.quantity,
          dimension: [step2?.dimensions.height, step2?.dimensions.width]
        };
        const response = await fetch(`https://api.izimade-ai.com:5099/get-configurator-url`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        });
        if (!response.ok) {
          throw new Error(`Error! status: ${response.status}`);
        }
        const result = await response.json();
        setLink(result.output);
      } catch (err: any) {
        console.log(err);
      } finally {
      }
    };
    getConfigUrl().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      if (event.origin.includes('elcia')) {
        let message = event.data;
        if (message.data && message.data.action === 'complete') {
          navigate('/step-4');
        }
        if (message.data && message.data.action === 'internal_serverReady') {
          setLoading(false);
        }
        return;
      }
      return;
    };
    window.addEventListener('message', handler);
    // clean up
    return () => window.removeEventListener('message', handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const configurator = <iframe src={link} title="configurator"></iframe>;

  return (
    <Spin spinning={loading} size="large">
      <div className="container step-3">
        <div className="left">
          <p className="step-title">3 - Configurateur</p>
          <BackButton title="← Retour" onClick={handleOnClick} />
        </div>
        <div className="middle">{configurator}</div>
        <div className="right">
          <Help
            default={defaultHelpVisible}
            title="Bienvenue dans l'aide"
            helpEntries={helpEntries}
          />
        </div>
      </div>
    </Spin>
  );
}
export default Step3;
