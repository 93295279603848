import './BackButton.css';

export function BackButton(props: BackButtonProps) {
  return (
    <p className="back-button" onClick={props.onClick}>
      {props.title}
    </p>
  );
}

export type BackButtonProps = {
  title: string;
  onClick: (() => any) | undefined;
};
