import './Content.css';
import { useState, useEffect, useContext, useRef } from 'react';
import Radio from 'components/Radio/Radio';
import { Spin } from 'antd';
import { appContext } from 'context/AppContext';
import logoMinus from 'assets/img/minus.png';
import logoPlus from 'assets/img/plus.png';
import logoEdit from 'assets/img/edit.png';

function Content(props: Step2Props) {
  const { step1, step2, setStep2 } = useContext(appContext);

  const [selected, setSelected] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');

  const [items, setItems] = useState(props.items);
  const setDimensions = useState({ width: 0, height: 0 })[1];
  const setQuantity = useState(1)[1];
  const [allFetched, setAllFetched] = useState(props.allFetched);

  const [modalState, setModalState] = useState(1);
  useEffect(() => {
    setModalState(props.modalState);
  }, [props.modalState]);

  const [loading, setLoading] = useState(false);

  const handleFetchAllItems = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://api.izimade-ai.com:5099/get-all-items?pricer_id=${step1?.pricerId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json'
          }
        }
      );
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
      const result = await response.json();
      var items = result.output.map((i: any) => {
        return {
          productCode: i.productCode,
          productName: i.productName,
          productGroupName: i.productGroupName,
          rangeName: i.rangeName,
          rangeGroupName: i.rangeGroupName,
          rangeCode: i.rangeCode || ''
        };
      });
      setDimensions({
        width: 0,
        height: 0
      });
      setQuantity(1);
      setAllFetched(true);
      setItems(items);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  var RadioContents = items.map((item) => {
    return (
      <div className="item-wrapper">
        <div className="square"></div>
        <p className="label">{item.productName}</p>
      </div>
    );
  });

  const choose = (productCode: string, _items?: []) => {
    setSelected(productCode);
    const selected = (_items ? _items : items).find((i) => i.productCode === productCode);
    setSelectedLabel(selected.productName);
    setStep2({
      productCode: productCode,
      rangeCode: selected.rangeCode,
      quantity: step2?.quantity!,
      dimensions: step2?.dimensions!,
      productName: selected?.productName
    });
  };

  const refWidth = useRef<HTMLInputElement>(null);
  const handleRefWidthClick = () => {
    if (null !== refWidth.current) {
      refWidth.current.focus();
    }
  };
  const refHeight = useRef<HTMLInputElement>(null);
  const handleRefHeightClick = () => {
    if (null !== refHeight.current) {
      refHeight.current.focus();
    }
  };

  const increaseQuantity = () => {
    const curr = step2?.quantity!;
    setStep2({
      productCode: step2?.productCode!,
      rangeCode: step2?.rangeCode!,
      quantity: curr + 1,
      dimensions: step2?.dimensions!,
      productName: step2?.productName || ''
    });
  };

  const decreaseQuantity = () => {
    const curr = step2?.quantity!;
    setStep2({
      productCode: step2?.productCode!,
      rangeCode: step2?.rangeCode!,
      quantity: curr - 1,
      dimensions: step2?.dimensions!,
      productName: step2?.productName || ''
    });
  };

  const onWidthChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setStep2({
      productCode: step2?.productCode!,
      rangeCode: step2?.rangeCode!,
      quantity: step2?.quantity!,
      dimensions: {
        width: newValue,
        height: step2?.dimensions.height!
      },
      productName: step2?.productName || ''
    });
  };

  const onHeightChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setStep2({
      productCode: step2?.productCode!,
      rangeCode: step2?.rangeCode!,
      quantity: step2?.quantity!,
      dimensions: {
        height: newValue,
        width: step2?.dimensions.width!
      },
      productName: step2?.productName || ''
    });
  };

  return (
    <>
      {modalState === 1 && (
        <>
          <Spin spinning={loading} size="large">
            <div className="step-2-wrapper">
              <div className="items-list">
                {items.map((i: any, idx: number) => {
                  return (
                    <Radio
                      value={i.productCode}
                      selected={selected}
                      key={i.productCode}
                      content={RadioContents[idx]}
                      onChange={choose}
                    />
                  );
                })}
              </div>
              {!allFetched && (
                <p className="show-all-wrapper" onClick={handleFetchAllItems}>
                  Tout afficher
                </p>
              )}
            </div>
          </Spin>
        </>
      )}
      {modalState === 2 && (
        <div className="step-2-container">
          <div className="back-link-wrapper">
            <p
              onClick={() => {
                props.setModalState(1);
              }}
            >
              ← Retour
            </p>
          </div>
          <div className="title-wrapper">
            <p className="title">{selectedLabel}</p>
          </div>
          <div className="form-wrapper">
            <div className="product-image-wrapper"></div>
            <div className="form">
              <p className="title">Dimension et quantité</p>
              <div className="dimensions-wrapper">
                <p className="title">Dimensions:</p>
                <div className="input-wrapper">
                  <p className="label">Hauteur:</p>
                  <input
                    ref={refHeight}
                    onChange={onHeightChange}
                    value={step2?.dimensions.height}
                    type="number"
                    name="height"
                    id="height"
                  />
                  <img src={logoEdit} alt="" onClick={handleRefHeightClick} />
                </div>
                <div className="input-wrapper">
                  <p className="label">Largeur:</p>
                  <input
                    ref={refWidth}
                    onChange={onWidthChange}
                    value={step2?.dimensions.width}
                    type="number"
                    name="width"
                    id="width"
                  />
                  <img src={logoEdit} alt="" onClick={handleRefWidthClick} />
                </div>
              </div>
              <div className="quantity-wrapper">
                <p className="title">Quantité:</p>
                <div className="quantity-input-wrapper">
                  <div className="quantity-button" onClick={decreaseQuantity}>
                    <img src={logoMinus} alt="" />
                  </div>
                  <p className="quantity">{step2?.quantity}</p>
                  <div className="quantity-button" onClick={increaseQuantity}>
                    <img src={logoPlus} alt="" />
                  </div>
                </div>
              </div>
              <div className="hl"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Content;

export type Step2Props = {
  items: Array<any>;
  allFetched: boolean;
  quantity: number;
  dimensions: { width: string; height: string };
  modalState: 1 | 2;
  setModalState: (s: 1 | 2) => void;
};
