import 'App.css';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { appContext, Step1, Step2 } from './context/AppContext';
function App() {
  const [step1, setStep1] = useState<Step1>(null);
  const [step2, setStep2] = useState<Step2>(null);
  return (
    <appContext.Provider
      value={{ step1: step1, setStep1: setStep1, step2: step2, setStep2: setStep2 }}
    >
      <Outlet />
    </appContext.Provider>
  );
}

export default App;
