import { Button } from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';

function Step4() {
  const navigate = useNavigate();
  const handleOnClick = () => navigate('/');

  return (
    <div className="container">
      <div className="left"></div>
      <div className="middle">
        <h1>
          Merci ! La configuration à bien <br />
          été prise en compte
        </h1>
        <Button
          disabled={false}
          type="default"
          onClick={handleOnClick}
          title="Retour à l’accueil"
        ></Button>
      </div>
      <div className="right"></div>
    </div>
  );
}

export default Step4;
